import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  unisex: {
    data: [],
    fetching: false,
    error: null,
  },
  kids: {
    data: [],
    fetching: false,
    error: null,
  },
};


const getJerseyRequest = (state, action) => {
  const key = action.payload; // Ensure this is a string, 'unisex' or 'kids'
  state[key].fetching = true;
  state[key].error = null;
};

const getJerseySuccess = (state, action) => {
  const { key, data } = action.payload;
  state[key].data = data.result; // Ensure data.result is an array
  state[key].fetching = false;
};

const getJerseyFailed = (state, action) => {
  const { key, error } = action.payload;
  state[key].error = error;
  state[key].fetching = false;
};


const jerseySlice = createSlice({
  name: 'jersey',
  initialState,
  reducers: {
    getJerseyRequest,
    getJerseySuccess,
    getJerseyFailed
  },
});

export const jerseyActions = jerseySlice.actions;
export const jerseyReducer = jerseySlice.reducer;
