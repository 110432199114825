import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import CSS

import slide0 from '../../assets/slide0.png';
import slide1 from '../../assets/slide1.png';
import slide2 from '../../assets/slide2.png';
import slide3 from '../../assets/slide3.png';
import slide4 from '../../assets/slide4.png';
import slide5 from '../../assets/slide5.png';
import slide6 from '../../assets/slide6.jpg';
import slide7 from '../../assets/slide7.jpg';

const ImageCarousel = () => {
  const images = [
    slide0,
    slide7,
    slide6,
    slide1,
    slide2,
    slide3,
    slide4,
    slide5,
    
  ];

  return (
    <div style={{ width: '100%', maxHeight: '750px', overflow: 'hidden' }}>
      <Carousel
        showArrows={true}
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
        transitionTime={500}
        dynamicHeight={true}
      >
        {images.map((img, index) => (
          <div key={index}>
            <img src={img} alt={`Slide ${index}`} style={{ width: '100%', height: 'auto', maxHeight: '750px', objectFit: 'cover' }} />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ImageCarousel;
