
import React, { useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from './theme';
import './index.css'; // Or App.css
import './components/styles/styles.scss';
import '@fontsource/poppins'; // Defaults to weight 400
import { useDispatch, Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import store from './store';
import { handleGetEvent } from './store/thunk/events';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { commonActions } from './store/slices/common';
import Home from './components/Home';
import PaymentSuccess from './components/invoice/PaymentSuccess';
import EventDetail from './components/event-detail/EventDetail';
import Checkout from './components/checkout/Checkout';
import InvoicePage from './components/invoice/InvoicePage';
import ERecipt from './components/invoice/ERecipt';
import NotFound from './components/NotFound';
import ParticipantsList from './components/participants/ParticipantsList';
import Monitor from './components/monitor/Monitor';
import Dashboard from './components/dashboard/Dashboard';

// import '@fontsource/poppins/400.css'; // Weight 400
// import '@fontsource/poppins/700.css'; // Weight 700

const App = () => {
  const dispatch = useDispatch();

  /* useEffect(() => {
    dispatch(handleGetEvent());
  }, [dispatch]); */
  const { visible, message, type } = useSelector((state) => state.common.toast);

  useEffect(() => {
    if (visible) {
      toast[type || 'info'](message, {
        // position: toast.POSITION.BOTTOM_LEFT,
        position: 'top-right',
        onClose: () => dispatch(commonActions.hideToast()),
      });
    }
  }, [visible, message, type, dispatch]);

  return (
    <>
      <Router>
        <ScrollToTop /> {/* Add ScrollToTop here */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/event" element={<EventDetail />} />
            <Route path="/event/:id" element={<EventDetail />} />
            <Route path="/checkout/:id" element={<Checkout />} />
            <Route path="/invoice" element={<InvoicePage />} />
            <Route path="/e-ticket/:id" element={<ERecipt />} />
            <Route path="/payment" element={<PaymentSuccess />} />
            <Route path="/notfound" element={<NotFound />} />
            <Route path="/participants" element={<ParticipantsList />} />
            <Route path="/monitor/:id" element={<Monitor />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="*" element={<Navigate to="/notfound" />} />
          </Routes>
      </Router>
      <ToastContainer autoClose={1500} style={{ minHeight: 50 }} />
    </>
  );
};

const WrappedApp = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Provider>
);

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};


export default WrappedApp;
