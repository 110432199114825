// src/utils/navigationUtils.js
export const handleNavigation = (e) => {
  e.preventDefault(); // Prevent the default link navigation

  const currentUrl = new URL(window.location.href);
  const hostnameParts = currentUrl.hostname.split('.');

  // Check if there is a subdomain
  if (hostnameParts.length > 2) {
    // Remove the subdomain
    hostnameParts.shift();
    const newHostname = hostnameParts.join('.');

    // Construct the new URL without the subdomain
    const newUrl = `${currentUrl.protocol}//${newHostname}/`;
    window.location.href = newUrl;
  } else {
    // If no subdomain, just navigate to root
    window.location.href = "/";
  }
};
  
export const handleNavigationHome = () => {
  const currentUrl = new URL(window.location.href);
  const hostnameParts = currentUrl.hostname.split('.');

  // Check if there is a subdomain
  if (hostnameParts.length > 2) {
    // Remove the subdomain
    hostnameParts.shift();
    const newHostname = hostnameParts.join('.');

    // Construct the new URL without the subdomain
    const newUrl = `${currentUrl.protocol}//${newHostname}/`;
    window.location.href = newUrl;
  }
};
  