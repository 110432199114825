import ConnectionInstance from '../services/connection-instance';

export default function localStorageAvailable() {
  try {
    const key = '__some_random_key_you_are_not_going_to_use__';
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export const jwtDecode = (token) =>{
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );

  return JSON.parse(jsonPayload);
}


export const setSession = (accessToken) => {
  if (accessToken) {
    // Store token in localStorage
    localStorage.setItem('accessToken', accessToken);

    // Set default Authorization header for axios
    ConnectionInstance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  } else {
    // Remove token from localStorage
    localStorage.removeItem('accessToken');

    // Remove Authorization header from axios
    delete ConnectionInstance.defaults.headers.common['Authorization'];
  }
};
