import React from 'react';
import { Typography, Grid } from '@mui/material';

const Ticket = ({ ticket }) => {
    const {
        ticket_name,
        ticket_price,
        ticket_price_early,
        ticket_date_early,
        ticket_availability,
    } = ticket;

    return (
        <Grid>
            <br />
            <Typography variant="h6" component="div">
                {ticket_name}
            </Typography>
            <Typography color="textSecondary">
                Regular Price: Rp {ticket_price.toLocaleString('id-ID')}
            </Typography>
            {/* {ticket_date_early && (
                <>
                    <Typography color="textSecondary">
                        Early Bird Price: Rp {ticket_price_early.toLocaleString('id-ID')}
                    </Typography>
                    <Typography color="textSecondary">
                        Early Bird Date: {new Date(ticket_date_early).toLocaleDateString('id-ID')}
                    </Typography>
                </>
            )} */}
            {/* <Typography color="textSecondary">
                Availability: {ticket_availability ? ticket_availability : 'N/A'}
            </Typography> */}
        </Grid>
    );
};

export default Ticket;
