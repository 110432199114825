const ThemeVariation = {
    Primary: 'primary',
    Secondary: 'secondary',
    Third: 'third',
    Fourth: 'fourth',
    Base: '#33c4f9',
    Ticket: '#3285fa',
    Checkout: '#3246fa',
    Confirm: '#32FAAB',
    HeaderForm: '#32FAEF',
    Register: '#85DBFA',
    Warning: '#FAEE32',
    Reject: '#FA325F',
    Green: '#529945'
};  

export default ThemeVariation;