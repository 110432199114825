import React, { useState, useEffect, useMemo } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Grid,
  Typography,
  Box,
  Container,
  Card,
  CardContent,
  Breadcrumbs,
  Link,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import * as Yup from 'yup';
import FormikTextField from '../element/textfield/FormikTextField';
import ThemeVariation from '../../theme/theme-variation';
import Layout from '../layout';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { commonActions } from '../../store/slices/common';
import { handleGetTicket } from '../../store/thunk/events.js';
import { handleGetEventDetail } from '../../store/thunk/events.js';
import ConfirmationDialog from './ConfirmationDialog';
import TicketDynamic from './TicketDynamic.jsx';
import TicketSkeleton from './TicketSkeleton';
import dayjs from 'dayjs';

const { v4: uuidv4 } = require('uuid');

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validateParticipant = {
  fullName: Yup.string().required('Full Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  birth: Yup.date().required('Date of birth is required'),
  gender: Yup.string().required('Gender is required'),
  nik: Yup.string().required('KTP/Passport is required'),
  phone: Yup.string().required('Phone is required'),
  nationality: Yup.string().required('Nationality is required'),
  address: Yup.string().required('Address is required'),
  bloodType: Yup.string().required('Blood Type is required'),
  emergencyContactName: Yup.string().required('Emergency Contact Name is required'),
  emergencyContactPhone: Yup.string().required('Emergency Contact Phone is required'),
  // jerseySize: Yup.string().required('Jersey Size is required'),
  // jerseySize: Yup.string(),
  // jerseySizeKids: Yup.string()
};

/* const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Full Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  // phone: Yup.string().required('Phone is required'),
  phone: Yup.string().matches(phoneRegExp).required('Phone is required'),
  formTenK: Yup.array().of(Yup.object().shape(validateParticipant)),
  formFiveK: Yup.array().of(Yup.object().shape(validateParticipant)),
  formComTenK: Yup.array().of(Yup.object().shape(validateParticipant)),
  formComFiveK: Yup.array().of(Yup.object().shape(validateParticipant)),
}); */

const generateValidationSchema = (tickets) => {
  const shape = {
    fullName: Yup.string().required('Full Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().matches(phoneRegExp).required('Phone is required'),
  };

  tickets.forEach(ticket => {
    shape[ticket.ticket_tag] = Yup.array().of(
      Yup.object().shape({
        ...validateParticipant,
        jerseySize: Yup.string().test(
          'jerseySize-or-jerseySizeKids',
          'Either Jersey Size or Jersey Size Kids is required',
          function(value) {
            return value || this.parent.jerseySizeKids;
          }
        ),
        jerseySizeKids: Yup.string().test(
          'jerseySize-or-jerseySizeKids',
          'Either Jersey Size or Jersey Size Kids is required',
          function(value) {
            return value || this.parent.jerseySize;
          }
        ),
      })
    );
  });

  return Yup.object().shape(shape);
};

const BillingOrderForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useParams();
  const [totalCostDiscount, setTotalCostDiscount] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState(null);
  const [participants, setParticipants] = useState({});
  const [sumTicketCost, setSumTicketCost]  = useState(0);

  // const { data, fetching, error } = useSelector((state) => state.event.tickets);
  const { data: ticketData, fetching: ticketFetching, error: ticketError } = useSelector((state) => state.event.tickets);
  const { data: eventData, fetching: eventFetching, error: eventError } = useSelector((state) => state.event.detail);
  const eventFormulaData = eventData?.result?.event_formula?.data
  const eventFormula = eventData?.result?.event_formula?.formula
  
  const dataTickets = useMemo(() => {
    return ticketData.result || [];
  }, [ticketData.result]);

  useEffect(() => {
    if (id) {
      dispatch(handleGetTicket(id));
      dispatch(handleGetEventDetail(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    const initialTickets = dataTickets.reduce((acc, ticket) => {
      acc[ticket.ticket_tag] = [];
      return acc;
    }, {});
    setParticipants(initialTickets);
  }, [dataTickets]);
  const validationSchema = generateValidationSchema(dataTickets);

  const initialValues = {
    fullName: '',
    email: '',
    phone: '',
    dataTickets
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setFormData(values);
    setOpenDialog(true);
    setSubmitting(false);
  };

  const handleConfirm = async () => {
    try {
      // Initialize cost variables
      let totalCost = 0;
      const costs = {};
  
      // Convert birth field to Date
      const convertDateToString = (date) => {
        return dayjs(date).format('YYYY-MM-DD'); // Change the format string as needed
      };
  
      // Process formData dynamically based on dataTickets
      const processedParticipants = {};
  
      dataTickets.forEach(ticket => {
        const ticketName = ticket.ticket_tag;
        const ticketPrice = ticket.ticket_price;
        const participants = formData[ticketName]?.map(person => ({
          ...person,
          birth: convertDateToString(person.birth)
        })) || [];
  
        processedParticipants[ticketName] = participants;
        const ticketCost = participants.length * ticketPrice;
        costs[ticketName] = ticketCost;
        totalCost += ticketCost;
      });
  
      // Check if all participant arrays are empty
      const allEmpty = Object.values(processedParticipants).every(participants => participants.length === 0);
  
      if (allEmpty) {
        dispatch(commonActions.showToast({ message: 'Please complete the participant data', type: 'warn' }));
        return; // Stop the function if the validation fails
      }
  
      const data = {
        booking: {
          idBooking: uuidv4(),
          fullName: formData.fullName,
          phone: formData.phone,
          email: formData.email
        },
        payment: {
          ...costs,
          totalCost,
          totalCostDiscount,
          sumTicketCost,
          redeemCode: formData.redeemCode,
        },
        participants: processedParticipants,
        dataTickets
      };
  
      console.log('Form submission data:', data);
      navigate('/invoice', { state: { ...data } });
    } catch (error) {
      console.error('Form submission error:', error);
    }
  };
  
  const handleApplyRedeemCode = (val) => {
    const discountAmount = validateRedeemCode(val);

    if (discountAmount) {
      setDiscount(discountAmount);
      setTotalCostDiscount(
        totalCost - discountAmount
      );
    } else {
      dispatch(commonActions.showToast({ message: 'Invalid Redeem Code', type: 'error' }));
    }
  };

  const validateRedeemCode = (code) => {
    // Example validation logic
    const validCodes = {
      'DISCOUNT10': 10000,
      'DISCOUNT20': 20000,
    };
    return validCodes[code] || 0;
  };

  const THEME = ThemeVariation.Secondary;

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="#" onClick={() => navigate(-2)}>
      HOME
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      href="#"
      onClick={() => navigate(-1)}
    >
      Event
    </Link>,
    <Typography key="3" color="text.primary">
      Checkout
    </Typography>,
  ];

  return (
    <Layout 
      theme={THEME}
      showHeaderSpacing={false}
      color={eventData?.result.event_name !== undefined ?? eventData?.result.event_name.toUpperCase() === 'JAWARA RUN 2024' ? eventData?.result.color : ThemeVariation.Base}
    >
      <Container>
        <Stack mt={2}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        <Grid >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => {
            let totalCost = 0, taxTotal = 0, tlHtm = 0;
            const hasTicketsWithValues = dataTickets.some(ticket => (values[ticket.ticket_tag] || []).length > 0);
            const htm = eventData?.result?.event_name === 'HEROES TRAIL RUN' ? true : false;
          
            return (
              <Box component={Form} sx={{ p: 3 }}>
                <Grid container spacing={2}>
                  {/* Column 1: Billing Information and Form 10K */}
                  <Grid item xs={12} md={7}>
                    <Card sx={{ width: '100%', mb: 2 }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography variant="h4" gutterBottom>
                          Billing Information
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormikTextField name="fullName" label="Full Name" />
                          </Grid>
                          <Grid item xs={12}>
                            <FormikTextField name="email" label="Email" />
                          </Grid>
                          <Grid item xs={12}>
                            <FormikTextField name="phone" label="Phone" />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                    {
                      ticketFetching && <TicketSkeleton />
                    }
                    {
                      dataTickets.map((ticket,i) => {
                        return(<TicketDynamic key={i} ticket={ticket} dataTickets={dataTickets} form={values} name={ticket["ticket_tag"]} index={i}/>)
                      })
                    }
                  </Grid>

                  {/* Column 2: Payment Information */}
                  <Grid item xs={12} md={5}>
                    <Card sx={{ mb: 2 }}>
                      <CardContent sx={{ flex: '1 0 auto' }}>
                        <Typography variant="h4" gutterBottom>
                        Payment Information
                        </Typography>
                          <TableContainer>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={{ width: '30%' }}>Ticket Name</TableCell>
                                  <TableCell style={{ width: '10%' }}>Participants</TableCell>
                                  <TableCell style={{ width: '40%' }}>Cost per Ticket</TableCell>
                                  <TableCell style={{ width: '20%' }}>Total Cost</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {dataTickets.map((ticket, i) => {                                  
                                  const participants = values[ticket.ticket_tag] || [];
                                  const ticketCost = participants.length * ticket.ticket_price;
                                  const step1 = htm ? ticketCost * 0.04 : ticketCost * 0.09;
                                  taxTotal += step1;
                                  totalCost += ticketCost;
                                  tlHtm += participants.length * 20000;
                                  // setSumTicketCost(htm ? (tlHtm + totalCost+(taxTotal+6000)) : totalCost+(taxTotal+6000));
                                  // aesi
                                  // setSumTicketCost(totalCost);

                                  const calculateTotalCost = (formula, sumTicketCost) => {
                                    // console.log(formula);
                                    // console.log(sumTicketCost);
                                    
                                    const modifiedFormula = formula === undefined ? sumTicketCost : formula?.replace(/n/g, sumTicketCost);
                                    try {
                                      return eval(modifiedFormula);
                                    } catch (error) {
                                      console.error("Error evaluating formula:", error);
                                      return 0;
                                    }
                                  };
                                
                                  const totalCosts = calculateTotalCost(eventFormula, totalCost);
                                  setSumTicketCost(totalCosts);
                                  
                                  return participants.length === 0 ? null : (
                                    <TableRow key={i}>
                                      <TableCell>{ticket.ticket_name}</TableCell>
                                      <TableCell>{participants.length}</TableCell>
                                      <TableCell>Rp. {ticket.ticket_price.toLocaleString('id-ID')}</TableCell>
                                      <TableCell>Rp. {ticketCost.toLocaleString('id-ID')}</TableCell>
                                    </TableRow>
                                  );
                                })}
                              
                              {/* <TableRow>
                                <TableCell colSpan={3} align="right"><strong>Tax (5% + Rp 6.000,-) x 4% :</strong></TableCell>
                                <TableCell><strong>Rp. {((taxTotal + 6000)).toLocaleString('id-ID')}</strong></TableCell>
                              </TableRow> */}
                              {eventFormulaData?.length !== 0 && (
                                <>
                                {eventFormulaData?.map((item, index) => (
                                  <TableRow key={index}>
                                    <TableCell colSpan={2}></TableCell>
                                    <TableCell colSpan={1} align="left">
                                      <small>{item.name}</small>
                                    </TableCell>
                                    <TableCell>
                                      <strong>
                                        {item.operation === '*' 
                                          ? `Rp. ${(totalCost * item.value).toLocaleString('id-ID')}` 
                                          : `Rp. ${item.value.toLocaleString('id-ID')}`
                                        }
                                      </strong>
                                    </TableCell>
                                  </TableRow>
                                ))}
                                <TableRow>
                                  <TableCell colSpan={2}></TableCell>
                                  <TableCell colSpan={1} align="left">
                                    <strong>Total Cost:</strong>
                                  </TableCell>
                                  <TableCell>
                                    <strong>Rp. {sumTicketCost?.toLocaleString('id-ID')}</strong>
                                  </TableCell>
                                </TableRow>
                              </>
                              )}

                                {/* { hasTicketsWithValues &&  (
                                  <>
                                    <TableRow>
                                      <TableCell colSpan={2}></TableCell>
                                      {
                                        htm ?
                                        <>
                                          <TableCell colSpan={1} align="left"><small>Tiket masuk Gunung Mas <br />Rp 20.000/Ticket</small></TableCell>
                                          <TableCell><strong>Rp. {tlHtm.toLocaleString('id-ID')}</strong></TableCell>
                                        </> :
                                        <>
                                          <TableCell colSpan={1} align="left"><small>Tax (5%):</small></TableCell>
                                          <TableCell><strong>Rp. {((totalCost*0.05)).toLocaleString('id-ID')}</strong></TableCell>
                                        </>
                                      }
                                    </TableRow>
                                    <TableRow>
                                    <TableCell colSpan={2}></TableCell>
                                      <TableCell colSpan={1} align="left"><small>Convenience Fee :</small></TableCell>
                                      <TableCell><strong>Rp. {((totalCost === 0 ? 0 : 6000)).toLocaleString('id-ID')}</strong></TableCell>
                                    </TableRow>
                                    <TableRow>
                                    <TableCell colSpan={2}></TableCell>
                                      <TableCell colSpan={1} align="left"><small>Service Charge (4%):</small></TableCell>
                                      <TableCell><strong>Rp. {((totalCost*0.04)).toLocaleString('id-ID')}</strong></TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell colSpan={2}></TableCell>
                                      <TableCell colSpan={1} align="left"><strong>Total Cost:</strong></TableCell>
                                      <TableCell><strong>Rp. {sumTicketCost.toLocaleString('id-ID')}</strong></TableCell>
                                    </TableRow>
                                  </>
                                )} */}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        
                        {/* <Grid container spacing={2}>
                          <Grid item xs={12} sx={{ mt: 2 }}>
                            <FormikTextField name="redeemCode" label="Redeem Code" />
                            <Button
                              variant="contained"
                              color="secondary"
                              sx={{ mt: 1, borderRadius: '20px' }}
                              disabled={totalCost === 0 }
                              onClick={() => {handleApplyRedeemCode(values.redeemCode)}}
                            >
                              Apply Code
                            </Button>
                          </Grid>
                        </Grid> */}

                        {/* {
                          dataTickets.map((ticket,i) => {
                            const participants = values[ticket.name] || [];
                            const ticketCost = participants.length * ticket.ticket_price;
                            totalCost += ticketCost;
                            return(
                              participants.length === 0 ? null :
                              <Typography variant="body1" sx={{ mt: 2 }} key={i}>
                                {ticket.ticket_name}: <strong>Rp. {(participants.length * ticket.ticket_price).toLocaleString('id-ID')}</strong>
                              </Typography>
                              )
                            }
                          )  
                        } */}

                        {/* <Typography variant="h6" sx={{ mt: 2 }}>
                          Total Cost: <strong>Rp. {totalCost.toLocaleString('id-ID')}</strong>
                        </Typography>
                        <TextField
                          label="Total"
                          value={totalCost.toLocaleString()}
                          fullWidth
                          margin="normal"
                          InputProps={{ readOnly: true }}
                          style={{display: 'none'}}
                          onChange={()=> {
                            setTotalCost(totalCost)
                            if(totalCost === 0 ){
                              setTotalCostDiscount(0)
                            }
                          }} >
                        */}

                        {/* <Typography variant="h6" sx={{ mt: 2 }}>
                          Total Cost Discount: Rp {totalCostDiscount.toLocaleString()}
                        </Typography> */}
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          sx={{ mt: 3, borderRadius: '20px' }}
                          disabled={isSubmitting}
                        >
                          Checkout
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
          );
        }}
        </Formik>
        </Grid>
        <ConfirmationDialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          onConfirm={handleConfirm}
        />
      </Container>
    </Layout>
  );
};

export default BillingOrderForm;
