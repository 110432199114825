import React from 'react';
import { Card, CardContent, Grid, Typography, Skeleton, Box, Divider, List, ListItem } from '@mui/material';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const TicketSkeleton = () => {
  return (
    <Card sx={{ display: 'flex', width: '100%', mb: 2 }}>
      <CardContent sx={{ flex: '1 0 auto', position: 'relative' }}>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} md={8}>
            <Skeleton variant="text" width={200} height={32} />
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <ConfirmationNumberIcon />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width={100} height={24} />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <EventIcon />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width={150} height={24} />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item>
                <LocationOnIcon />
              </Grid>
              <Grid item>
                <Skeleton variant="text" width={200} height={24} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', height: '100%' }}>
              <Skeleton variant="rectangular" width={150} height={36} sx={{ borderRadius: '20px' }} />
            </Box>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        <Typography component="div" variant="h6" sx={{ mt: 2 }}>
          Participants List
        </Typography>
        <List>
          {[...Array(3)].map((_, index) => (
            <ListItem key={index}>
              <Skeleton variant="text" width="100%" height={24} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default TicketSkeleton;
