import React from 'react';
import styled from 'styled-components';
import Header from '../header/header';
import Footer from '../Footer';
// import Header from '../Header';

const SECTION_SPACING = 20; // or import this from your constants

const HeaderSpacing = styled.div`
  height: ${SECTION_SPACING / 14}rem;
`;

const Layout = ({ children, showHeaderSpacing = true, theme, color }) => {
  return (
    <>
      <Header theme={theme} color={color}/>
      {/* <Header /> */}
      {showHeaderSpacing && <HeaderSpacing />}
      {children}
      <Footer color={color} />
      {/* <Footer theme={theme} /> */}
    </>
  );
};

export default Layout;
