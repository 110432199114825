import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../layout/index.jsx';
import ThemeVariation from '../../theme/theme-variation.js';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  TablePagination, Button, Box, Typography,
  Link,
  Container,
  Stack,
  Breadcrumbs,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';

const ParticipantsTable = ({ participants, events }) => {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedEvent, setSelectedEvent] = useState('');

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEventChange = (event) => {
    setSelectedEvent(event.target.value);
    setPage(0);
  };

  const filteredParticipants = selectedEvent 
    ? participants.filter(participant => participant.eventId === selectedEvent) 
    : participants;

  // Ensure participants are sliced according to pagination settings
  const paginatedParticipants = participants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const THEME = ThemeVariation.Secondary;

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="#" onClick={() => navigate('/')}>
      HOME
    </Link>,
    <Link underline="hover" key="2" color="inherit">
      Participant List
    </Link>
  ];
  return (
    <Layout 
      theme= {THEME}
      showHeaderSpacing= {false}
      color= {ThemeVariation.Base}
    >
      <Container>
        <Stack spacing={2} mb={3} mt={4}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        <Paper>
      <FormControl fullWidth>
        <InputLabel id="event-select-label">Filter by Event</InputLabel>
        <Select
          labelId="event-select-label"
          id="event-select"
          value={selectedEvent}
          label="Filter by Event"
          onChange={handleEventChange}
        >
          <MenuItem value=''><em>None</em></MenuItem>
          {events.map(event => (
            <MenuItem key={event.id} value={event.id}>{event.ticket_tag}</MenuItem>
          ))}
        </Select>
      </FormControl>
      
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Event</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredParticipants.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((participant) => (
              <TableRow key={participant.id}>
                <TableCell>{participant.id}</TableCell>
                <TableCell>{participant.ticket_tag}</TableCell>
                <TableCell>{participant.email}</TableCell>
                <TableCell>{events.find(event => event.id === participant.eventId)?.ticket_tag}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredParticipants.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
      </Container>
    </Layout>
  );
};

export default ParticipantsTable;
