import { jerseyActions } from '../slices/jersey'; // Adjust the import path as necessary
import { getJersey } from '../../services/jersey';

export const handleGetJersey = (key, param) => async (dispatch) => {
  try {
    dispatch(jerseyActions.getJerseyRequest(key));
    const response = await getJersey(param);
    dispatch(jerseyActions.getJerseySuccess({ key, data: response.data }));
  } catch (error) {
    dispatch(jerseyActions.getJerseyFailed({ key, error: error.message }));
  }
};
