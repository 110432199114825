import { createTheme } from '@mui/material';

const theme = createTheme( {
  colors: {
    primary: '#e49c86',
    secondary: '#11d2ef',
    third: '#537bdf',
    fourth: '#ffd661',
    error: '#ed4337',
    success: '#5cb85c',
    light: '#f5f5f5',
  },
  schema: {
    gray: {
      base: '#f5f5f5',
      light: '#e1e1e1',
      regular: '#888',
      medium: '#444',
      dark: '#111',
    },
  },
  palette: {
    background: {
      default: '#f0faff', // Set your desired background color here
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    h1: {
      fontFamily: 'Poppins, sans-serif',
    },
    h2: {
      fontFamily: 'Poppins, sans-serif',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 400,
    },
    // Add more styles as needed
  },
});


export default theme;
