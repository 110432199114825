import { authActions } from '../slices/auth'; // Adjust the import path as necessary
import { authLogin } from '../../services/auth'; // Adjust the import path as necessary
import { commonActions } from '../slices/common';
import { setSession } from '../../utils/localStorageAvailable';

export const handleLogin = (param) => async (dispatch) => {
  try {
    dispatch(authActions.loginRequest());
    const resData = await authLogin(param);
    const res = resData.data;
    if(res?.token){
      setSession(res?.token)
      dispatch(authActions.loginSuccess());
      // window.location.reload();
      setTimeout(() => {
        dispatch(commonActions.showToast({ message: 'Login success', type: 'success' }));
      }, 1000);
    }else{
      dispatch(authActions.loginFailed(res));
      dispatch(commonActions.showToast({ message: 'Login failed, please try again', type: 'error' }));
    }
    return res;
  } catch (error) {
    dispatch(authActions.loginFailed(error.message));
    dispatch(commonActions.showToast({ message: 'Login failed, please try again', type: 'error' }));
    throw error;
  }
};