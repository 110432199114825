import React from 'react';
import { Field, getIn } from 'formik';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

// Define a styled TextField
const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: '20px',
  },
}));

const DatePickerField = ({ name, label }) => (
  <Field name={name}>
    {({ field, form }) => {
      const value = field.value ? dayjs(field.value) : null;
      const error = getIn(form.errors, name) && getIn(form.touched, name);
      
      return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label={label}
            value={value}
            onChange={(date) => form.setFieldValue(field.name, date)}
            slots={{
              textField: (params) => (
                <CustomTextField
                  {...params}
                  fullWidth
                  error={!!error}
                  helperText={error ? getIn(form.errors, name) : ''}
                />
              ),
            }}
          />
        </LocalizationProvider>
      );
    }}
  </Field>
);

export default DatePickerField;
